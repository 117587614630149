//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
	document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + ' KOKUDO-JYUHAN';
};


// ハンバーガーメニュー
$(function(){
		$("#js_gnavMenu-openBtn").on("click", function() {
				var scrollTop = $(window).scrollTop();
				$(this).next().fadeToggle();
				$(this).toggleClass("js_gnavMenu-open");
				$('#wrapper').css({'position':'fixed','top':-scrollTop});
		});
});
$(function(){
		var w = $(window).width();
		var scrollTop = $(window).scrollTop();
		if (w < 3000) {
			$("#js_gnavMenu-closeBtn").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
			});
			$("#js_gnavMenu-closeSub").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
			});
			$("#js_gnavMenuLinkList a").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
			});
		}
});

// ホバーメニュー
$(function(){
  $('.hoverArea').hover(function() {
    var w = $(window).width();
    if (w > 768) {
      $(this).toggleClass('active');
      $(this).children('.hoverContent').stop().slideDown();
    }
  },
  function() {
    var w = $(window).width();
    if (w > 768) {
      $(this).toggleClass('active');
      $(this).children('.hoverContent').stop().slideUp();
    }
  });
});

// トグル
$(function(){
  $('.toggleBtn').click(function() {
    var w = $(window).width();
    if (w < 767) {
      $(this).toggleClass('active');
      $(this).next('.hoverContent').slideToggle();
    }
  });
});

// フッターの固定ボタン
$(document).ready(function(){
		// ページがスクロールされるまでは隠しておく
		$("#FixedFt").hide();
		// スクロールが始まったら
		$(window).on("scroll", function() {
				// 100pxスクロールしたら表示
				if ($(this).scrollTop() > 100) {
						$('#FixedFt').slideDown("fast");
				// ページの一番上では非表示
				} else {
						$('#FixedFt').slideUp("fast");
				}
				// ページの高さ
				var scrollHeight = $(document).height();
				//  表示画面の高さ ＋ ページに対するスクロール量
				var scrollPosition = $(window).height() + $(window).scrollTop();
				//  フッターの高さ
				var footHeight = $("#Footer").innerHeight();
				// フッターの位置（ページの高さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
				if ( scrollHeight - scrollPosition  <= footHeight ) {
				// 現在の下から位置が、フッターの高さの位置にはいったら
				// ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする
						$("#FixedFt").css({
								"position": "absolute",
								"bottom": footHeight,
						});
				} else {
				// それ以外の場合は元のcssスタイルを指定
						$("#FixedFt").css({
								"position": "fixed",
								"bottom": "-1px",
						});
				}
		});
});

// スムーズスクロール
$(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});

// スライダー
$(document).ready(function(){
  $('#mainSlide').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1200,
    infinite: true,
    draggable: true,
    autoplay: true,
    accessibility: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    fade: true,
  });
});

// スライダー
$(document).ready(function(){
  $(".slider").not('.slick-initialized').slick({
    autoplay: true,
    pauseOnDotsHover: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    dots: false,
    autoplaySpeed: 0,
    cssEase: 'linear',
    speed: 50000,
 });
});

// スライダー
$(function() {
  $('.thumb-item').not('.slick-initialized').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.thumb-item-nav'
  });
  $('.thumb-item-nav').slick({
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 400,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.thumb-item',
    focusOnSelect: true,
  });
});

//部屋画像
$(function() {
  $('.room-images').not('.slick-initialized').slick({
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 400,
    arrows: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
   {
    breakpoint: 768, //767px以下のサイズに適用
    settings: {
      slidesToShow:3
    }
   }
 ]
  });
});

